import React, {FC} from 'react';
import logo from './logo.svg';
import wsLogo from './logos/ws.svg';
import moooruLogo from './logos/moooru.svg';
import rtLogo from './logos/roomtemp.svg';
import {Box, Button, Chip, Container, Grid, Link, Stack, Typography} from "@mui/material";


interface LinkData {
    url: string
    text: string

}

interface ProjectInfo {
    title: string,
    mainLink: LinkData,
    tags: string[],
    body: string,
    oldBody?: string,
    image: string
    logoImg: string
}

const projects: ProjectInfo[] = [
    // {
    //     title: "roomtemp.io",
    //     mainLink: {url: "https://roomtemp.io", text: "roomtemp.io"},
    //     tags: ["User Experience Design", "Product Development"],
    //     body: "Unorganized and unnecessary meetings are a drag and cost millions." +
    //         " Coordinating and sharing information is critical to accomplishing organizational goals." +
    //         " Roomtemp helps meeting organizers understand which topics are important to discuss and which can" +
    //         " be deferred or handled offline. Respect everyone's time.",
    //     image: "/img/roomtemp_laptop_mockup.jpg",
    //     logoImg: rtLogo,
    // },
    // {
    //     title: "Oregon Wellness Lab",
    //     mainLink: {url: "https://oregonwellness.com", text: "oregonwellness.com"},
    //     tags: ["Brand Thesis", "Product Design"],
    //     body: "Humans are a tribal creatures. Gathered around campfires, early humans shared ideas and information" +
    //         " while developing trust and mutual understanding with their peers. The community, idea sharing, and" +
    //         " mentorship that happens around a campfire is essential to our wellbeing as humans. Could be a campfire," +
    //         " a community well, a church, a high school, or some other shared community resource that requires" +
    //         " consistent shared attendance. As the shared resources around us transform into in-home resources, or" +
    //         " morph into a streamlined business transaction, we are losing our connection spaces. We’ve lost the" +
    //         " places where we used to connect with other members of our tribe to share ideas and enjoy community." +
    //         "\n\n" +
    //         "Oregon Wellness Lab aims to design systems to facilitate local tribe discovery and connection.",
    //     image: "/img/owl_hero.jpg",
    //     logoImg: rtLogo,
    // },
    {
        title: "Moooru",
        mainLink: {url: "https://moooru.com", text: "moooru.com"},
        tags: ["Brand Strategy", "Product Design"],
        body:
            "Moooru aims to equip small scale ranchers with the critical data they need to make" +
            " the most informed decisions possible about the herds they look after. To connect these ranchers with" +
            " the community, the knowledge, and the resources they need to produce healthy, environmentally friendly" +
            " nutrition for their community.",
        oldBody: "Factory farming practices and the takeover of monolithic food corporations have pushed down the" +
            " price of food for consumers over the last few decades. Unfortunately, this price decrease comes at a" +
            " tremendous expense to the farm land, the environment, the small farmers health and livelihoods, and" +
            " the consumers health. This shift has pushed out many small farmers who work incredibly hard to keep" +
            " their practices healthy for the environment, the farm land, and the consumer." +
            "\n\n" +
            "Moooru aims to use technology to connect small scale farmers with the critical data they need to make" +
            " the most informed decisions possible about the herds they look after, and to connect the farmers with" +
            " the community, knowledge, and resources they need to produce healthy, eco-friendly nutrition for" +
            " their community.",
        image: "/img/moooru_mockup.jpg",
        logoImg: moooruLogo,
    },
    {
        title: "WildShield",
        mainLink: {url: "https://wildshield.com", text: "wildshield.com"},
        tags: ["Brand Strategy", "Product Design"],
        body:
            "Losing a species of wildlife is an awful tragedy for science and humanity. WildShield aims to leverage" +
            " technology and software solutions to amplify the efficacy of the heroes working to prevent species" +
            " loss in wildlife reserves and protected lands.",
        oldBody: "On March 19, 2018, the last male Northern White Rhino that exists on earth, Sudan, drew his last" +
            " breath. Though Sudan died of natural causes, his species was pushed to the brink of extinction by" +
            " illegal poaching. Killed only for their horns which sell for high prices on the black market due to" +
            " the misconception that rhino horns contain medicinal properties by certain populations. While this" +
            " species is now functionally extinct, poachers continue to target other Rhino and elephant species for" +
            "their tusks and horns and other animal byproducts sold on the black market." +
            "\n\n" +
            "Losing a species is a severe tragedy for science and humanity. WildShield aims to leverage technology" +
            " and software solutions to amplify the efficacy of the heroes working to prevent species loss in" +
            " wildlife reserves and protected lands.",
        image: "/img/wildshield_imac_mockup.jpg",
        logoImg: wsLogo,
    },
].reverse()


export function App() {
    return (
        <Container sx={{position: 'relative'}}>
            <header style={{padding: 40, textAlign: 'center'}}>
                <img style={{maxWidth: 60, marginTop: 120}} src={logo} alt="logo"/>
                <h2 style={{fontFamily: "'Finger Paint', monospaced", fontSize: '1.2rem', color: '#222'}}>Nice
                    Monkey</h2>
                <Box sx={{py: 15, mt: 0}}>

                    <Typography variant={'body2'}>
                        We build realtime intelligence tools to empower nice monkeys.
                    </Typography>
                    <Typography variant={'body2'} sx={{mt: 3}}>
                        Nice to <strong>earth</strong>, nice to <strong>animals</strong>, nice
                        to <strong>monkeys</strong>.
                    </Typography>
                    {/*<Button variant={'text'} color={'inherit'} sx={{mt: 2.5,}}> get in touch</Button>*/}
                </Box>
            </header>
            {projects.map((p, i) => {
                return <ProjectHighlightSection
                    key={p.title}
                    project={p}
                    reverseAlignment={i % 2 === 1}
                />
            })}
            <Footer/>
        </Container>
    );
}

const ProjectHighlightSection: FC<{
    project: ProjectInfo,
    reverseAlignment: boolean
}> = ({project: {title, body, image, mainLink, tags, logoImg}, reverseAlignment}) => {

    return (
        <Grid container direction={reverseAlignment ? 'row-reverse' : 'row'}
              sx={{py: 10, alignItems: 'center', textAlign: reverseAlignment ? 'right' : 'left'}}>
            <Grid item xs={12} md={8}>
                <Box sx={{padding: 3}}>
                    <img src={image} alt={title} style={{width: '100%'}}/>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box sx={{padding: 3}}>
                    <Stack direction={reverseAlignment ? 'row-reverse' : 'row'} alignItems={'center'} spacing={1}>
                        <Box sx={{height: 48, width: 48, textAlign: 'center'}} alignItems={'center'}>
                            <img style={{maxWidth: 48, height: 48}} src={logoImg} alt="logo"/>
                        </Box>
                        <Typography variant={'h1'} sx={{pb: 0, pt: 1}}>{title}</Typography>
                    </Stack>
                    <Stack direction={reverseAlignment ? 'row-reverse' : 'row'} spacing={1} sx={{my: 2}}>
                        {tags.map(tag => {
                            return (
                                <Chip key={tag} label={tag} size={'small'} sx={{
                                    borderRadius: .5,
                                    fontWeight: 700,
                                    textTransform: 'uppercase',
                                    fontSize: '.6rem',
                                    pt: .3,
                                    color: '#555',
                                }}/>
                            )
                        })}
                    </Stack>
                    <Typography variant={'body2'} sx={{whiteSpace: 'pre-wrap', fontWeight: 500, color: '#555'}}>
                        {body}
                    </Typography>
                    <Box sx={{my: 3}}>
                        <Link
                            variant={'body2'}
                            href={mainLink.url}
                            target={'_blank'}
                            underline={'hover'}
                            color={'secondary'}
                            sx={{mb: 3, fontWeight: 500}}>
                            {mainLink.text}
                        </Link>

                    </Box>
                    {/*<Box sx={{display: 'flex', justifyContent: reverseAlignment ? 'flex-end' : 'flext-start', mt: 2}}>*/}
                    {/*    {tags.map((tag, i) => {*/}
                    {/*        return (*/}
                    {/*            <Box key={tag} sx={{background: '#EEE',borderRadius: .5, px: 3, pt: .7, mx: i > 0 ? .5 : 0}}>*/}
                    {/*                <Typography*/}
                    {/*                    variant={'body2'}*/}
                    {/*                    sx={{*/}
                    {/*                        textTransform: 'uppercase',*/}
                    {/*                        fontSize: '.7rem',*/}
                    {/*                        fontWeight: 700,*/}
                    {/*                        color: '#444'*/}
                    {/*                    }}*/}
                    {/*                >*/}
                    {/*                    {tag}*/}
                    {/*                </Typography>*/}
                    {/*            </Box>*/}
                    {/*        )*/}
                    {/*    })}*/}

                    {/*</Box>*/}
                </Box>
            </Grid>
        </Grid>
    )
}

const Footer: FC = ({}) => {

    return (
        <Box sx={{my: 5, textAlign: 'center'}}>
            <Box sx={{marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img style={{maxWidth: 60}} src={logo} alt="logo"/>
                <img style={{maxWidth: 20, margin: 15}} src={'/img/black_heart.svg'} alt="heart"/>
                <img style={{maxWidth: 60, borderRadius: 5}} src={'/img/jane.jpg'} alt="heart"/>
            </Box>
            <Typography variant={'body2'} sx={{mt: 5}}>
                5% of ALL proceeds go to the&nbsp;
                <Link underline={'always'} target={'_blank'} href={"https://www.janegoodall.org/"}>
                    Jane Goodall Institute
                </Link>
            </Typography>
            {/*<Link underline={'always'}>*/}
            {/*    <Typography variant={'h5'} sx={{my: 5}}>*/}
            {/*        roy@nicemonkey.studio*/}
            {/*    </Typography>*/}
            {/*</Link>*/}
            <Button variant={'text'} sx={{my: 8}} href={"mailto:roy@nicemonkey.studio"}>
                roy@nicemonkey.studio
            </Button>

            <Typography variant={'body2'} sx={{my: 3, color: '#777', fontSize: '.7rem'}}>
                © {new Date().getFullYear()} Nice Monkey Studio LLC. All Rights Reserved.
            </Typography>
        </Box>
    )
}
