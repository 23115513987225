import {createTheme} from "@mui/material";
import {blue, grey} from "@mui/material/colors";

export const theme = createTheme({
    palette: {
        primary: {
            main: grey[900],
        },
        secondary: {
            main: blue[900],
        },
    },
    typography: {
        fontFamily: '"URWGeometric", "Helvetica", "Arial", sans-serif',
        fontWeightRegular: 500,

        h1: {
            fontWeight: 700,
            fontSize: '1.4rem',
            color: '#333',
        },
        h3: {
            fontWeight: 700,
            fontSize: '1.5rem',
            color: '#333',
        },
        h5: {
            fontWeight: 700,
            fontSize: '1rem',
            color: '#333',
        },
        h6: {
            fontWeight: 700,
            fontSize: '.75rem',
            color: '#333',
        },
        body2: {
            fontWeight: 600,
            fontSize: '.85rem',
            color: '#333',
        },
        button: {
            fontSize: '0.9rem',
            fontWeight: 800,
        },
    },

    components: {
        // Name of the component
        MuiLink: {
            styleOverrides: {
                root: {
                    // color: 'red',
                    // fontSize: '1rem',
                    fontWeight: 700,
                    cursor: 'pointer'



                }
            }
        },
        MuiButton: {
            styleOverrides: {

                sizeSmall: {
                    fontSize: '.6rem'
                },

                // Name of the slot
                outlined: {
                    borderWidth: 2,
                    '&:hover': {
                        borderWidth: 2
                    }
                },

                text: {
                    color: '#333',
                    textTransform: 'none',
                    textDecoration:'underline',
                    '&:hover': {
                    textDecoration:'underline',
                    }
                },

                root: {
                    // Some CSS
                    fontSize: '1rem',
                },
            },
        },
    },
});
